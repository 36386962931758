import HeaderLogo from "@/assets/dbw-logo.svg";
import { Link } from "@remix-run/react";

interface LogoProps {
  size?: "small" | "medium" | "large";
}

export const Logo = ({ size }: LogoProps) => {
  let logoClass = "";

  switch (size) {
    case "small":
      logoClass = "max-w-[150px] min-w-[100px]";
      break;
    case "medium":
      logoClass = "max-w-[300px] min-w-[100px]";
      break;
    case "large":
      logoClass = "max-w-[600px] min-w-[100px]";
      break;
    default:
      logoClass = "max-w-[300px] min-w-[100px]";
  }

  return (
    <div className={`${logoClass} items-center`}>
      <Link to="/">
        <img src={HeaderLogo} alt="Daily Bulk Wine Logo" />
      </Link>
    </div>
  );
};

export default Logo;
